<template>
	<div id="mapEcharts" style="width: 100%; height: 600px">

	</div>
</template>

<script>
	var myChart = {};
	var geoCoordMap = [{
			amount: 65,
			countryId: 167,
			district: "美国",
			id: 106131,
			latitude: "37.09024",
			longitude: "-95.712891",
		},
		{
			amount: 22,
			countryId: 31,
			district: "中国",
			id: 106132,
			latitude: "35.86166",
			longitude: "104.195397",
		},
	];
	import echarts from "echarts";
	import "echarts-gl";
	import "./world1.js";
	// import Qtal from './data-gl/lake.hdr'
	export default {
		data() {
			return {};
		},
		props: {
			userJson: {
				type: Array,
				default: function() {
					return;
				}
			}
		},
		created() {
			setTimeout(() => {
				this.initial();
			}, 200);
			geoCoordMap = this.userJson;
			// this.request("/statistics/intelligence-thermodynamic",{}).resolve((data) => {
			//     geoCoordMap = data
			// })
		},
		mounted() {
			myChart = echarts.init(document.getElementById("mapEcharts"));
			// console.log(myChart);
			// myChart.showLoading()
			window.addEventListener("resize", () => {
				myChart.resize();
			});
			// $(window).on('resize', noop => {
			//     myChart.resize()
			// })
		},
		destroyed() {
			myChart.dispose();
		},
		methods: {
			initial() {
				let series = [];
				series.push({
					type: "effectScatter",
					coordinateSystem: "geo",
					zlevel: 2,
					rippleEffect: {
						brushType: "stroke",
					},
					symbolSize: function(val) {
						return val.length + 6;
					},
					itemStyle: {
						normal: {
							color: {
								type: "radial",
								x: 0.5,
								y: 0.5,
								r: 0.5,
								colorStops: [{
										offset: 0,
										color: "rgba(200, 0, 3, 0.2)",
									},
									{
										offset: 0.8,
										color: "rgba(200, 0, 3, 0.8)",
									},
									{
										offset: 1,
										color: "rgba(200, 0, 3, 0.6)",
									},
								],
								global: false, // 缺省为 false
							},
						},
					},
					data: geoCoordMap.map(function(dataItem) {
						//console.log(dataItem);
						let a = [];
						a.push(dataItem.longitude);
						a.push(dataItem.latitude);
						a.push(dataItem.companyname);
						a.push(dataItem.companyimg);
						return {
							name: dataItem.district,
							value: a,
						};
					}),
				});

				let option = {
					title: {
						text: "",
						subtext: "",
						left: "left",
						textStyle: {
							color: "#fff",
						},
					},
					geo: {
						map: "world",
						zoom: '1.4',
						label: {
							emphasis: {
								show: true,
								color: "#003398",
							},
						},
						roam: true,
						itemStyle: {
							normal: {
								areaColor: "#d5d5d5",
								borderColor: "#909090",
							},
							emphasis: {
								areaColor: "#b8b9bb",
							},
						},
					},
					series: series,
					tooltip: {
						trigger: "item",
						backgroundColor: '#fff',
						borderColor: '#f5f5f5',
						borderWidth: 1,
						padding: [10, 20],
						textStyle: {
							color: '#000'
						},
						formatter(params) {
							// console.log(params);
							let text = "";
							text = `<img src="${params.value[3] ? params.value[3] : 'https://img0.baidu.com/it/u=3544832916,1519601524&fm=26&fmt=auto&gp=0.jpg'}"/> <br> <p style="text-align:center">${params.name}</p>`;
							return text;
						},
					},
				};
				myChart.on("click", (param) => {
					// console.log(param);
					let list = geoCoordMap.filter((item) => item.district == param.name);
					this.$emit("maplist", list);
				});
				myChart.setOption(option);
			},
		},
	};
</script>
